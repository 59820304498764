body {
    font-family: 'Montserrat', sans-serif;
    background-color: white;
    overflow-x: hidden; }
.disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
    cursor: no-drop; }
//antd modal
.ant-modal-mask {
    background-color: transparent; }
.cursor-pointer {
    cursor: pointer; }
.user-select-none {
    user-select: none; }
.app-header {
    z-index: 1 !important; }
.ant-notification {
    z-index: 20000; }

.grades-modale {
    width: 800px !important; }
.sidebar {
    height: 100%; }
.app-header {
    position: fixed; }
