//purple
$purple-400: #c1b3bf;
$purple-450: #a38e9f;
$purple-900: #65425f;
$purple-1000: #545871;

//gold
$gold-500: #d2ba7c;
$gold-450: #e4d6b0;
$gold-400: #ede3cb;
