.pagination-list-wrapper {
    .ant-pagination {
        li {
            margin: 0.25rem 0.25rem;
            &.ant-pagination-item {
                background-color: white;
                color: $gold-500;
                margin: 0.25rem 0.25rem;
                border: 1px solid $gold-400;
                &.ant-pagination-item-active {
                    background-color: $gold-500;
                    color: white; } } } } }
