.ps__rail-x {
    display: none !important; }
.scrollbar-container {
    padding-bottom: 3rem;
    overflow-x: hidden !important; }
.sidebar {
    padding: 1rem 0;
    background-color: white;
    overflow-x: hidden;
    // z-index: 1 !important
    .scrollbar-container {
        border-left: 2px solid gray;
        li {
            background-color: white;
            a {
                &:hover,&:focus {
                    background-color: rgba($purple-900,0.4);
                    color: darken($purple-900,5%); } }
            a {
                padding: 0.75rem 0rem 0.75rem 1rem;
                outline: none !important;
                &.active {
                    background-color: rgba($purple-900,0.4);
                    color: darken($purple-900,5%); }
                .nav-icon {
 } }                    // display: none
            .badge-white {
                border: 1px solid $purple-900;
                color: $purple-900;
                background-color: white; }
            &.section-header {
                padding-right: 2rem;
                color: $purple-900 !important;
                font-weight: 700;
                a {
                    padding-right: 0.25rem;
                    color: $purple-900;
                    font-weight: 700; } }
            &.section-item {
                padding-right: 2rem;
                a {
                    padding-right: 1rem;
                    color: $purple-900;
                    // font-size: 12px
                    font-weight: 600;
                    border-right: 3px solid rgba($purple-900,0.3); } } } } }
@media (max-width: 767px) {
    .app-body {
        margin-top: 0; }
    .sidebar {
        padding: 0; } }


.section-header {
    padding-right: 19px !important;
    color: $purple-900 !important;
    font-weight: 700;
    a {
        padding-right: 0.25rem;
        color: $purple-900 !important;
        font-weight: 700; } }

.section-item {
    padding-right: .5rem !important;
    a {
        padding-right: .5rem !important;
        color: $purple-900 !important;
        // font-size: 12px
        font-weight: 600;
        border-right: 3px solid rgba($purple-900,0.3); } }
