.ant-select-selection--single {
    padding: 0 !important; }
.ant-select-selection__rendered {
    ul {
        width: 100%;
        .ant-select-search__field {
            border: none !important;
            &:focus {
                border: none; } } } }
.form-wrapper {
    .form {
        .form-input-wrapper {
            position: relative;
            margin-bottom: 1.5rem;
            .input-label {
                margin-bottom: 0.35rem;
                user-select: none;
                padding: 0 1rem;
                &.required {
                    &::after {
                        content: '*';
                        display: inline-block;
                        padding: 0 0.5rem;
                        color: red; } } }
            .input-wrapper {
                .ant-switch-checked {
                    background-color: $purple-900; }
                .file-input-wrapper {
                    display: block;
                    .ant-upload-select {
                        width: 100%; } }
                .MuiFormControl-root {
                    width: 100%;
                    .MuiInputBase-root {
                        &::before,&::after {
                            display: none; }
                        .MuiInputBase-input {
                            width: 100%;
                            border-radius: 3px;
                            border: 1px solid $purple-900;
                            height: 40px;
                            font-weight: 500;
                            padding: 0px 0.75rem;
                            box-sizing: border-box;
                            transition: all 300ms;
                            font-size: 15px;
                            &:focus {
                                border: 1px solid darken( $purple-900,20%); } } } }

                .ant-upload-picture-card-wrapper {
                    width: auto;
                    .ant-upload-select-picture-card {
                        margin: 0; } }
                .ant-calendar-picker {
                    width: 100%; }
                .react-phone-number-input__row,.PhoneInput,.react-tel-input {
                    height: 100%;
                    width: 100%;
                    padding: 0 !important;
                    .flag-dropdown {
                        background-color: transparent; }
                    .selected-flag {
                        display: flex;
                        justify-content: flex-end;
                        .arrow {
                            left: 10px; } }
                    input {
                        direction: ltr;
                        text-align: right;
                        &::placeholder {
                            color: rgba(gray,0.4); } }
                    #phone-form-control {
                        padding-left: 0;
                        padding-right: 48px;
                        width: 100%; }


                    .react-phone-number-input__icon,.PhoneInputCountryIcon {
                        display: flex;
                        svg {
                            width: 100%; } }
                    input {
                        background-color: transparent;
                        border: none; } }
                .ant-input,.ant-select-selection,.ant-input-number,.ant-calendar-picker-input,.ant-time-picker-input,.react-phone-number-input,.phone_input,.PhoneInputInput,.react-tel-input {
                    width: 100%;
                    border-radius: 3px;
                    border: 1px solid rgba( $purple-900,0.5);
                    height: 40px;
                    font-weight: 500;
                    transition: all 300ms;
                    font-size: 15px;
                    padding: 0 1rem;
                    &:focus {
                        border: 1px solid darken( $purple-900,20%); }
                    &.ant-select-selection--multiple {
                        height: auto;
                        min-height: 40px; }
                    .ant-input-number-handler-wrap {
                        display: none; }
                    .ant-select-selection__rendered,.ant-input-number-input-wrap {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        .ant-select-selection__choice {
                            border-radius: 10px;
                            background-color: rgba($gold-500,0.3);
                            border: 1px solid $gold-500;
                            margin: 7px 3px;
                            color: $purple-900;
                            font-weight: 500; }
                        .ant-input-number-input {
                            height: 100%; }
                        // .ant-select-selection-selected-value

 } }                        //     // height: 100%
                &.underline-white-input {
                    .ant-input,.ant-select-selection,.ant-input-number {
                        border-radius: 0px;
                        border: none;
                        border-bottom: 1px solid white;
                        color: white !important;
                        background-color: transparent;
                        box-shadow: 0px 0px 0px !important;
                        &::placeholder {
                            color: rgba(white,0.8); } } } } } } }


@media (max-width: 767px) {
    .form-wrapper {
        .form {
            .form-input-wrapper {
                .input-wrapper {
                    .MuiFormControl-root {
                        .MuiInputBase-root {
                           .MuiInputBase-input {
                               height: 35px;
                               font-size: 13px; } } }
                    .ant-input,.ant-select-selection,.ant-input-number,.ant-time-picker-input,.react-phone-number-input {
                        height: 35px;
                        font-size: 13px;
                        &.ant-select-selection--multiple {
                            height: auto;
                            min-height: 35px; }
                        .ant-select-selection__rendered,.ant-input-number-input-wrap {
                            .ant-select-selection__choice {
                                margin: 4px 3px; } } } } } } } }

@media (max-width: 420px) {
    .form-wrapper {
        .form {
            .form-input-wrapper {
                .input-wrapper {
                    .MuiFormControl-root {
                        .MuiInputBase-root {
                            .MuiInputBase-input {
                                height: 33px;
                                font-size: 12px; } } }
                    .ant-input,.ant-select-selection,.ant-input-number,.ant-time-picker-input,.react-phone-number-input {
                        height: 33px;
                        font-size: 12px;
                        &.ant-select-selection--multiple {
                            height: auto;
                            min-height: 33px; } } } } } } }
