.details-wrapper {
    .img-wrapper {
        display: flex;
        justify-content: center;
        .img-holder {
            width: 100px;
            height: 100px;
            border-radius: 6px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover; } } }
    .details-list {
        .list-item {
            margin-bottom: 1rem;
            display: flex;
            .list-item-content {
                display: block;
                margin: 0 0.5rem;
                flex-grow: 1;
                width: 0px;
                .pills-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    border-radius: 6px;
                    padding: 1rem;
                    background-color: rgba($gold-500,0.1);
                    border: 1px solid $gold-500;
                    .pill {
                        margin: 0.25rem;
                        background-color: rgba($gold-500,0.2);
                        border-radius: 20px;
                        padding: 8px;
                        border: 1px solid $gold-500;
                        color: $purple-900; } } } } } }
