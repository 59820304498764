.purple-900-table-wrapper {
    border: 1px solid rgba($purple-900,0.4);
    border-radius: 5px;
    .purple-900-table {
        width: 100%;
        text-align: center !important;
        padding: 0 0.5rem;
        min-width: 740px;
        thead {
            background-color: rgba($purple-900, 0.3);
            color: $purple-900; }
        tbody {
            tr {
                vertical-align: text-top;
                td {
                    .icon {
                        // width: 20px
                        cursor: pointer; }
                    // .stars-wrapper
                    //     white-space: nowrap
                    //     .star
                    //         width: 17px
 }                    //         margin: 0 0.25rem
                &:not(:last-child) {
                    td {
                        border-bottom: 1px solid rgba($purple-900,0.4); } } } } } }

.vertical-table-purple-900-wrapper {
    border: 1px solid rgba($purple-900,0.4);
    border-radius: 5px;
    overflow: hidden;
    .table {
        border-top: none;
        margin: 0;
        tr {
            th {
                background-color: rgba($purple-900,0.2);
                border-bottom: 1px solid rgba($purple-900,0.4);
                border-left: 1px solid rgba($purple-900,0.4);
                white-space: nowrap; }

            td {
                width: 100%; } } } }
@media (max-width: 800px) {
    .purple-900-table-wrapper {
        overflow-x: auto;
        overflow-y: hidden; } }
// @media (max-width: 420px)
//     .darkBlue-table-wrapper
//         .darkBlue-table
//             tbody
//                 tr
//                     td
//                         .icon
//                             width: 17px

